:root {
  --toastify-toast-width: 380px;
}

.toastify-custom .Toastify__toast--success {
  background-color: #28C76F;
}

.toastify-custom .Toastify__toast--error {
  background-color: #D82F23;
}

.toastify-custom .Toastify__toast-body {
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  font-weight: normal;
  line-height: 18px;
}

.toastify-custom  .Toastify__close-button {
  align-self: center;
}